export const team = [
  {
    id: 1,
    name: "Ashif Shaikh",
    title: "Convener, MRC",
    description:
      "Born in India to an excluded community, Ashif cofounded Jan Sahas (“People’s Courage”) with the dream of empowering socially excluded communities drawing from his own lived experience. He’s since grown the organization from five volunteers to a network of thousands, and they have helped millions of migrant workers and their families access safety nets, supported and rehabilitated survivors of sexual abuse, trafficking, and manual scavengers. In 2020, Shaikh launched the Migrants Resilience Collaborative, a grassroots-led effort to closely partner with governments, private sector, and nonprofits to support vulnerable migrant families become socio-economically resilient. He was awarded the Harvard Kennedy School's Gleitsman International Award in 2022, and named Schwab Foundation Social Innovator of the Year in 2020 and an Ashoka Fellow in 2016. Ashif serves as a member of various committees of the Government of India.",
    image: "/images/Ashif-Shaikh3x.jpg",
    linkedIn: "",
  },
  {
    id: 1,
    name: "Nitish Narain",
    title: "Managing Director",
    description:
      "Nitish Narain is the Managing Director for MRC. He provides strategic and operational leadership for scaling MRC’s impact across millions of vulnerable and marginalized workers. Nitish has over 15 years of experience in programmatic leadership, building teams and coalitions, resource mobilization, and organizational development. He also brings global expertise in designing and scaling viable last mile service delivery models and products for the next billion users, largely from low-income communities. Previously, Nitish led the Financial Inclusion and Policy practice at MicroSave Consulting, where he oversaw project delivery across Asia and Africa while managing relations with ministries, regulators, multi-laterals, and financial service providers. Prior to this, he was with Bharat Financial Inclusion Ltd., leading a team of 100+ people implementing complex microfinance operations in India.",
    image: "/images/team/nitish.jpg",
    linkedIn: "",
  },
  {
    id: 2,
    name: "Binu Arickal",
    title: "Director, Regional Operations",
    description:
      "Binu leads Regional Operations for South and South East Asia. In this role, he plays a crucial part in steering the establishment of a comprehensive, long-term program dedicated to migrants across five countries in the region. A management graduate, Binu's transition into the development sector was fuelled by the profound satisfaction derived from transforming lives. He comes with diverse experience, having worked with various organizations at grassroots, national and international levels. Prior to this, he led strategic initiatives in WaterAid India. He has worked with with Dan Church Aid and Samarthan - Centre for Development Support which involved coordination with various stakeholders, advocate for safe migration and understand the nuances of decentralized governance.",
    image: "/images/team/Binu Color.jpg",
    linkedIn: "",
  },
  {
    id: 3,
    name: "Kapil Shah",
    title: "Director, Technology",
    description:
      "Kapil leads the Migrants Resilience Collaborative’s work around leveraging technology to drive program and operational efficiency with a goal to design and strengthen tech-based solutions on social protection. Prior to this, Kapil worked as a technology consultant with Capgemini for over 13 years.  His expertise lies in leveraging technology to drive business solutions and enhance organizational efficiency. Kapil is passionate about using technology to drive positive change and meaningful impact on society, collaborating with nonprofits, government agencies, and stakeholders to achieve social impact goals. He has a Master’s degree in Management of Information Systems from Texas A&M University and Bachelor’s degree in Computer Engineering.",
    image: "/images/team/kapil.jpg",
    linkedIn: "",
  },
  {
    id: 4,
    name: "Madhumitha Hebbar",
    title: "Director, Strategic Initiatives",
    description:
      "Madhumitha Hebbar leads MRC’s work around creating systems change as well as impact measurement. She is responsible for building and rigorously testing a portfolio of big bets to help millions of internal migrant workers in India access social security benefits cheaper, faster, and better. Prior to this, Madhumitha worked as a social protection specialist for eight years independently as well as with Oxford Policy Management. She supported governments and multilateral agencies in delivering the universal social protection agenda across South Asia, South-East Asia and Southern Africa. She has a Master's degree in Development Studies from Erasmus University Rotterdam.",
    image: "/images/team/madhumitha.jpg",
    linkedIn: "",
  },
  {
    id: 5,
    name: "Rohit Siddareddy",
    title: "Director, Partnerships",
    description:
      "Rohit leads the Migrant Resilience Collaborative’s partnerships with government and industry with an aim to build a strong narrative for adoption of social security delivery through strategic and operational partnerships.  He has over a decade of experience spanning across investments, consulting and construction. Prior to this, he managed impact investments for Tamil Nadu Infrastructure Fund Management Corporation (TNIFMC), founded and ran a construction firm that executed NHAI projects and worked in the Major Projects Advisory practice of KPMG in New York. Rohit has a Bachelor's degree in Mechanical Engineering and a Master's degree in Construction Management from Columbia University in New York.",
    image: "/images/team/Rohit Colour.jpg",
    linkedIn: "",
  },
  {
    id: 6,
    name: "Swati Jha",
    title: "Director, Program",
    description:
      "Swati leads the overall program design and implementation of MRC program in India. Prior to this, she has worked at the American India Foundation, United Way Delhi, Hole-in-the-Wall Education, and also co-founded and education not profit, ASPIRE. With an experience spanning over 20 years, she specialises in academic research and programs aimed at empowering vulnerable and migrant communities through education. She brings this unique lens to the  Migrants Resilience Collaborative by designing interventions that leverage opportunities at the intersection of grassroot engagement and systemic change to innovate on program design.",
    image: "/images/team/Swati Jha.jpg",
    linkedIn: "",
  },
  {
    id: 7,
    name: "Varun Behani",
    title: "Director, Strategy and Research",
    description:
      "Varun leads MRC's overall strategy development which includes strategy and research verticals. As a founding team member of MRC, he helped set up the collaborative, playing a key role across strategy development, fundraising, and team development. His current focus is on scaling MRC's work across South and Southeast Asia, and advancing MRC's mission on the global stage. Prior to this, Varun worked as a strategy consultant with The Bridgespan Group, supporting nonprofits and philanthropies in developing their missions.",
    image: "/images/team/varun.jpg",
    linkedIn: "",
  },
  {
    id: 8,
    name: "Anika Azhar ",
    title: "Country Coordinator, Bangladesh",
    description:
      "Anika leads operations and engagement in Bangladesh. She is a seasoned development professional situated in Dhaka, Bangladesh, with a wealth of experience spanning over 8 years. Her expertise lies in diverse areas, including migration, social protection, human trafficking, gender mainstreaming, financial inclusion and various projects and programs aligned with the UN's Sustainable Development Goals (SDGs), particularly within the UN belt. Within her domain, Anika specializes in technical assistance project design and management, stakeholder coordination, strategic advocacy and policy formulation. Her proficiency extends to holding multiple certifications as a trainer and master trainer in critical domains such as Sustainable Reintegration, Financial Literacy, Life Skills, Gender and Development.",
    image: "/images/team/anika.jpg",
    linkedIn: "",
  },
  {
    id: 9,
    name: "Govinda Neupane",
    title: "Country Coordinator, Nepal",
    description:
      "Govinda leads operations and engagement in Nepal. He is an experienced Development Professional with over 25 years of strategic leadership in the development sector, managing international NGOs, UN programs and projects. His expertise spans Human Rights, Governance, Climate Resilient Sustainable Development and multi-sectoral interventions. Prior to this, he has led transformative partnership management with 200+ NGOs, CSOs, government and private sector actors. He has a Master's degree in Sociology and Anthropology and a Bachelor's degree in business management from Tribhuvan University. He is actively involved in civil society movements and networks in Nepal, including the Association of INGOs (AIN) and Management Association of Nepal. He is an alumni of Harvard Business School, and serves as an international facilitator for Civil Society Academy, Germany.",
    image: "/images/team/Govinda.jpg",
    linkedIn: "",
  },
  {
    id: 10,
    name: "Priyansha Jain",
    title: "Senior Lead, Strategic Initiatives",
    description:
      "Priyansha leads MRC's engagements with government agencies and the private sector to design, test, and identify scalable solutions that improve access to social protection for informal workers. With previous roles at Dasra, Flipkart, and HDFC Life, she brings a unique blend of strategy, research, and partnership development skills to her work. Most recently, she led the development of MRC's textile sector strategy to enhance social protection in India's apparel supply chain. A strong advocate for gender equity, Priyansha also volunteers for various feminist causes outside work.",
    image: "/images/team/Priyansha Jain.jpg",
    linkedIn: "",
  },
  // {
  //   id: 12,
  //   name: "Panchali Banerjee",
  //   title: "Lead, Quality and Development",
  //   description:
  //     "Panchali Banerjee brings seven years of experience in academia and more than three years in social protection within the development sector. She focuses on enhancing MRC’s frontline staff capacity to ensure robust program outcomes, particularly in providing social protection for vulnerable migrant households, through improved last mile connectivity. Prior to this, Panchali has worked as a Research Manager with IFMR LEAD on financial inclusion and social protection and as a Project Manager with Swaniti Initiative on labour and social protection, in collaboration with Oak Foundation and National Democratic Institute. She holds a PhD in Economics from Jadavpur University, India.",
  //   image: "/images/team/Priyansha Jain.jpg",
  //   linkedIn: "",
  // },
  {
    id: 12,
    name: "Gaurav Dubey",
    title: "Senior Lead, Programs",
    description:
      "Gaurav Dubey plays a pivotal role in implementing the MRC program in India, striving to enhance social mobility among internal migrants. With a strong background in the development sector, he’s deeply engaged in grassroots community institutions development, livelihood enhancement, blue-collar skill empowerment, and migrant welfare. Over-a-decade-long career, Gaurav has made significant contributions to esteemed organisations such as Grant Thornton, Gram Unnati, and the Government of Rajasthan. His expertise in program planning and implementation is refined through diverse professional experiences. He holds a master’s degree in Management and Rural Development from National Institute of Rural Development & Panchayati Raj, Hyderabad.",
    image: "/images/team/Gaurav.jpg",
    linkedIn: "",
  },
  // {
  //   id: 13,
  //   name: "Azfar Karim",
  //   title: "Senior Lead, Monitoring, Impact and Evidence",
  //   description:
  //     "Azfar Karim has nine years of experience leading large-scale Impact Evaluations for Social Development programs in Asia and Africa. Within MRC, he heads the Monitoring and Evaluation vertical, overseeing external evaluation partners to drive program enhancement and evidence-based scaling. Previously, Azfar served as an Advisor at Innovations for Poverty Action and as a Monitoring and Evaluation Lead for multiple International NGOs, including Central Square Foundation, One Acre Fund, Laudes Foundation, Precision Agriculture for Development, and J-PAL. Azfar has worked extensively in leading diverse multicultural and multilingual teams. He has also contributed to numerous evaluations, and publication reports. Azfar holds a bachelor's degree from Dr B.C. Roy Engineering College, Durgapur.",
  //   image: "/images/team/Azfar Karim.jpg",
  //   linkedIn: "",
  // },
  {
    id: 14,
    name: "Shadab Alam",
    title: "Lead, Worker Protection",
    description:
      "Shadab Alam, an experienced lawyer with 11 years of expertise, leads the worker protection vertical of MRC.  His efforts focus  on safe migration, assuring decent working conditions and eradication of forced labour, child labour, and human trafficking,  along with  policy enforcement, analysis, and advocacy to ensure justice and governance. With five years dedicated to migration issues, he's passionate about enhancing access to justice and policy reform. Collaborating with various stakeholders, he drives systemic changes. Previously, he contributed to Ford Foundation and ICSSR projects, analysing policies within the Indian media's regulatory landscape. His legal practice at Patna's courts deepened his grassroots understanding. Shadab holds a Master's degree in Culture Media & Governance from Jamia Milia Islamia University and a degree in Law Aligarh Muslim Umiversity.",
    image: "/images/team/Shadab Alam.jpg",
    linkedIn: "",
  },
];
