import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import ExpandBox from "../components/ExpandBox/ExpandBox";
import { team } from "../data/team-new";
import Seo from "../components/seo";
const TeamPage = () => {
  return (
    <Layout>
      <Seo title="Team" url="https://migrantresilience.org/team" />
      {/* <div className="w-full flex flex-col justify-center items-center relative"> */}
      <div className="bg-sectionBlue w-full flex flex-col">
        <Banner src="/images/team/team-banner.jpg" />
        {/* <div className="w-full flex flex-col justify-center items-center relative z-0">
          <h1 className="text-center w-10/12 mt-4 text-black text-2xl font-bold">
            Jan Sahas, Global Development Incubator and EdelGive Foundation are
            strategic partners that manage the governance and program delivery
            of MRC. The management is guided by a group of eminent steering
            council members and advisors.
          </h1>
          <img
            src="/images/dot-pattern.png"
            className="absolute top-0 md:left-20 left-0 w-80 object-contain opacity-80 -z-1"
          ></img>
        </div> */}

        <div className="w-full py-12 lg:px-28 md:px-12 px-4">
          <div className="w-full flex flex-col justify-end items-start relative z-0">
            <h2 className="uppercase text-left mb-8 text-darkblue md:text-4xl text-2xl transition-all duration-300 ease font-bold">
              Our Team
            </h2>
          </div>
          <div className="grid 2xl:grid-cols-4 lg:grid-cols-5 lg:grid-rows-2 md:grid-cols-3 grid-rows-auto grid-cols-2 4xl:gap12 gap-8">
            {team
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((member, key) => (
                <ExpandBox data={member} key={"member" + key} />
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default TeamPage;
