import React, { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";

function Content({ data, disabled, isExpanded }) {
  return (
    <motion.div
      style={{ opacity: disabled ? 0.2 : 1 }}
      className={`z-1 h-full w-full flex shrink-0 ${
        isExpanded
          ? "flex-col mt-4 overflow-y-scroll"
          : "flex-col transform lg:hover:scale-[1.02] mt-0 overflow-hidden"
      } transition-all duration-300 ease cursor-pointer justify-start items-start group relative`}
    >
      <img
        src={data.image}
        alt=""
        layoutId="memberImg"
        className={`${
          isExpanded
            ? "hover:shadow-none w-40 w-full"
            : "hover:shadow-xl shadow-darkblue/10 w-full"
        } flex shrink-0 transition-all duration-300 ease 2xl:h-56 4xl:h-72 ${
          isExpanded ? "md:h-40 h-40" : "md:h-52 h-40"
        } object-cover object-top rounded-xl hover:grayscale`}
      />

      <div
        className={`flex flex-col w-full ${
          isExpanded ? "pl-0 mt-4" : "pl-0 mt-0"
        }`}
      >
        <div
          className={`flex ${
            isExpanded ? "justify-start" : "justify-between"
          } items-start mt-1`}
        >
          <h3
            className={`text-darkblue font-bold 4xl:text-2xl text-sm ${
              isExpanded
                ? "mt-0 lg:text-lg md:text-xl w-72"
                : "mt-2 lg:text-lg md:text-lg w-fit"
            } `}
            style={{ lineHeight: "1" }}
          >
            {data.name}
          </h3>
          {data.linkedIn && (
            <a href={data.linkedIn} target="_blank" rel="noreferrer">
              <FaLinkedin
                className={`text-blue md:text-2xl text-lg ${
                  isExpanded ? "" : "lg:mt-1 mt-2"
                }`}
              />
            </a>
          )}
        </div>

        <p
          className={`${
            isExpanded ? "lg:text-sm text-xs" : "md:text-sm text-xs"
          } text-black font-semibold mt-2`}
          style={{ lineHeight: 1.1 }}
        >
          {data.title}
        </p>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, top: "-6rem" }}
            animate={{ opacity: 1, top: "0rem" }}
            dangerouslySetInnerHTML={{ __html: data.description }}
            className={` ${
              isExpanded ? "mt-2 md:mt-2" : "md:mt-2"
            } leading-tight text-left w-full md:text-sm text-xs`}
            style={{ color: "#616161" }}
          ></motion.div>
        )}
      </div>
    </motion.div>
    // <motion.h1
    //   className="title"
    //   layoutId="title"
    //   style={{ opacity: disabled ? 0.2 : 1 }}
    // >
    //   {day}
    // </motion.h1>
  );
}

function ExpandedCard({ children, onCollapse }) {
  return (
    <>
      <motion.div
        className="md:w-[60vw] w-[90vw] md:max-h-[80vh] max-h-[95vh] fixed shadow-4xl flex md:top-[15vh] top-[15vh] shadow-2xl md:left-[20vw] left-[5vw] bg-white z-100 md:p-8 p-6 overflow-y-scroll"
        layoutId="expandable-card"
      >
        {children}
        <div
          className="absolute top-4 right-8 cursor-pointer"
          onClick={onCollapse}
        >
          <FaTimes className="text-darkblue text-xl" />
        </div>
      </motion.div>
      {/* <motion.p
        className="card expanded secondary"
        onClick={onCollapse}
        transition={{ delay: 0.3 }}
        initial={{ opacity: 0, top: "6rem" }}
        animate={{ opacity: 1, top: "3rem" }}
      >
        Today is clear
      </motion.p> */}
    </>
  );
}

function CompactCard({ children, onExpand, disabled }) {
  return (
    <motion.div
      className="w-full bg-transparent z-0"
      layoutId="expandable-card"
      onClick={disabled ? undefined : onExpand}
    >
      {children}
    </motion.div>
  );
}

function MemberBlock({ memberID, data, onCollapse, onExpand, disabled }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const collapseMember = () => {
    setIsExpanded(false);
    onCollapse();
    document.body.style.overflow = "unset";
  };

  const expandMember = () => {
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
    setIsExpanded(true);
    onExpand();
  };

  return (
    <div className="w-full">
      <AnimateSharedLayout>
        {isExpanded ? (
          <ExpandedCard onCollapse={collapseMember} memberID={memberID}>
            <Content
              memberID={memberID}
              disabled={disabled}
              isExpanded={isExpanded}
              data={data}
            />
          </ExpandedCard>
        ) : (
          <CompactCard
            onExpand={expandMember}
            disabled={disabled}
            memberID={memberID}
          >
            <Content
              memberID={memberID}
              disabled={disabled}
              isExpanded={isExpanded}
              data={data}
            />
          </CompactCard>
        )}
      </AnimateSharedLayout>
    </div>
  );
}

export default function ExpandBox(props) {
  const [expandedMember, setExpandedMember] = useState();
  return (
    <MemberBlock
      key={props.data.id}
      memberID={props.data.id}
      data={props.data}
      disabled={
        expandedMember !== props.data.id && expandedMember !== undefined
      }
      onExpand={() => setExpandedMember(props.data.id)}
      onCollapse={() => setExpandedMember()}
    />
  );
}
